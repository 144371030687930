<template>
	<c-dialog ref="dialog" :title="'选择'+title" width="600" @resolve="resolve">
		<c-table ref="table" height="400">
			<c-table-column
				type="selector"
				width="20"
				:multiple="!!multiple"
                :selected="row => row.data.checked"
			></c-table-column>

			<!-- <c-table-column
				label="账号名"
				width="120"
				name="user_name"
			></c-table-column> -->

			<c-table-column
				label="姓名"
				width="120"
				name="user_realname"
			></c-table-column>

			<c-table-column
				label="手机号"
				width="170"
				name="phone"
			></c-table-column>

			<c-table-column
				label="账号类型"
				width="100"
				name="user_type"
			>
				<template #default="{data}">
					<span v-if="data.user_type == 1">超级管理员</span>
					<span v-if="data.user_type == 2" style="color:red">系统管理员</span>
					<span v-if="data.user_type == 3">员工</span>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean
		},
		data() {
			return {
				title:"系统用户"
			}
		},
		methods: {
			open(team_id,title,user_type) {
				this.title = title;
				this.$refs.dialog.open();
				this.$refs.table.load({
					url: '/sys_user/list',
					data: {
						team_id: team_id||'',
						user_type: user_type
					}
				});
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择'+this.title
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>