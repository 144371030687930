<template>
	<c-dialog ref="dialog" title="选择物料组件" width="650" @resolve="resolve">
		<m-search ref="search" @search="searchHandle">
<!--			<m-search-item label="物料编码">
				<c-input name="product_code"></c-input>
			</m-search-item>-->
			<m-search-item label="物料名称">
				<c-input name="product_name"></c-input>
			</m-search-item>
		</m-search>
		<c-table ref="table" height="400" >
			<c-table-column
				type="selector"
				width="15"
				:multiple="!!multiple"
			></c-table-column>
			
<!--			<c-table-column
				label="物料编码"
				width="120"
				name="product_code"
			></c-table-column>-->

			<c-table-column
				label="物料名称"
				width="120"
				name="product_name"
			></c-table-column>

			<c-table-column
				label="物料单位"
				name="unit"
				width="50"
			>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	export default {
		props: {
			multiple: Boolean,
			customer: Boolean
		},
		data() {
			return {
				product_id:0,
				material_ids:[]
			}
		},
		methods: {
			//普通产品弹框
			open(product_id,material_ids) {
				this.$refs.search.clear();
				this.$refs.dialog.open();
				this.product_id = product_id||0;
				this.material_ids = material_ids||0;
				this.searchHandle();
			},
			//搜索产品
			searchHandle(data){
				if (!data){
					data = {};
				}
				data.product_id = this.product_id;
				data.material_ids = this.material_ids;
				this.$refs.table.load({
					url: '/bom/material/list',
					data
				});
			},
			
			resolve(stop) {
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择物料'
					});
					return;
				}
				this.$emit('resolve', Array.from(data, item => item.data));
			}
		}
	}
</script>