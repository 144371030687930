<template>
	<page>
		<module>
			<c-form ref="form" column="2" unit-width="95%" style="max-width:1100px">
				<c-title>基本信息</c-title>
				<c-form-item label="盘点单号">
					<c-input name="inventory_number" value="自动生成" disabled></c-input>
				</c-form-item>
				<c-form-item label="仓库" required>
					<c-select name="warehouse_id" options="WAREHOUSE_LIST" @change="changeWarehouse"></c-select>
				</c-form-item>
				<c-form-item label="盘点原因" required>
					<c-select :options="DIC_INVENTORY_REASON" name="reason_code"></c-select>
				</c-form-item>
				<c-form-item label="操作员工" required>
					<c-input type="hidden" name="yg_id"></c-input>
					<div class="flex-center">
						<c-input name="yg_name"  maxlength="50" readonly disabled></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="selectQuality">选择员工</c-button>
					</div>
				</c-form-item>
				<c-form-item label="说明备注">
					<c-input name="remark" ></c-input>
				</c-form-item>
				<c-title>盘点单{{this.warehouse_type == 1?"产品":"物料"}}明细</c-title>
				<!--展示订单待派工的产品列表-->
				<module enable-flex>
					<div class="flex-start" style="padding-bottom: 5px">
						<c-button @click="selectProduct(1)" v-if="warehouse_type == 1">新增产品</c-button>
						<c-button @click="selectProduct(2)" v-if="warehouse_type == 2">新增物料</c-button>
					</div>
					<c-table ref="product_table" :data="productData" :paging="false" border>
<!--						<c-table-column
							:label="warehouse_type==1?'产品编码':'物料编码'"
							name="product_code"
							width="100"
						>
						</c-table-column>-->
					
						<c-table-column
							:label="warehouse_type==1?'产品名称':'物料名称'"
							name="product_name"
							width="300"
						>
						</c-table-column>
						
						<c-table-column
							label="类型"
							name="product_type"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="data.product_type==1">产品</span>
								<span v-if="data.product_type==2">物料</span>
							</template>
						</c-table-column>
						
						<c-table-column
							label="颜色"
							name="product_spec"
							width="150"
						>
							<template #default="{data}">
							    <span v-if="data.product_spec">{{data.product_spec}}</span>
								<span v-else>--</span>
							</template>
						</c-table-column>
					
						<c-table-column
							label="库存数量"
							name="stock_count"
							width="80"
						>
							<template #default="{data}">
							    <span v-if="data.db_stock_count">{{data.db_stock_count}}</span>
								<span v-else>0</span>
							</template>
						</c-table-column>
					
						<c-table-column
							label="盘点数量"
							name="product_count"
							width="80"
							required
						>
							<template #default="{data}">
							    <c-input name="product_count" type="number" decimal="5" range="0,999999"  width="80" v-model="data.product_count"></c-input>
							</template>
						</c-table-column>
						
						<c-table-column
							label="差数"
							name="product_count"
							width="60"
						>
							<template #default="{data}">
							    <span v-if="(data.product_count - data.db_stock_count) > 0" style="color:green">{{data.product_count - data.db_stock_count}}</span>
								<span v-else-if="(data.product_count - data.db_stock_count) == 0" style="color:green">正常</span>
								<span v-else style="color:red">{{parseFloat(data.product_count - data.db_stock_count).toFixed(2)}}</span>
							</template>
						</c-table-column>
						
						<c-table-column
							label="单位"
							name="unit"
							width="50"
						>
						</c-table-column>
											
					    <c-table-column
						    type="button"
						    label="操作"
						    width="50"
					    >
						    <template v-slot="{data}">
							    <c-table-button @click="removeProduct(data)">删除</c-table-button>
						    </template>
					    </c-table-column>
					</c-table>
				</module>
			</c-form>

			<template slot="button">
				<c-button color="main" size="form" @click="submit(-2)">保存草稿</c-button>
				<c-button color="main" size="form" @click="submit(1)">提交并核准</c-button>
			</template>
		</module>
		<!--选择员工-->
		<responsible-selector ref="qualitySelector" @resolve="selectQualityHandle"></responsible-selector>
		<!--产品选择弹框-->
		<product-selector ref="productSelector" multiple @resolve="handleProductSelect"></product-selector>
		<!--物料选择弹框-->
		<material-selector ref="materialSelector" multiple @resolve="handleProductSelect"></material-selector>
	</page>
</template>

<script>
import {mapState} from 'vuex'
import {asyncMapState} from '@/store'
import productSelector from '@/components/service/product_selector.vue'
import materialSelector from '@/components/service/bom_material_selector.vue'
import responsibleSelector from '@/components/service/sys_user_selector.vue'

export default {
	name: 'm_inventory_edit',
	props: {
		id: String
	},
	components: {
		responsibleSelector,productSelector,materialSelector
	},
	computed: {
		...mapState(['systemConfig']),
		...asyncMapState(['DIC_INVENTORY_REASON'])
	},
	data() {
		return {
			warehouse_type:1,
			productData:[]
		}
	},

	watch: {
		id() {
			this.getOrderDetail();
			this.loadOrderProduct();
		}
	},

	mounted() {
		if(this.id){
			this.getOrderDetail();
			this.loadOrderProduct();
		}
	},

	methods: {
		//查询订单详情
		getOrderDetail() {
			this.request({
				url: '/wms/inventory/detail',
				data: {
					id:this.id
				},
				loading: false,
				success: data => {
					this.warehouse_type = data.warehouse_type;
					this.$refs.form.set(data);
				}
			});
		},
		//加载盘点产品列表
		loadOrderProduct(){
			this.request({
				url: '/wms/inventory/product_list',
				data: {id: this.id},
				success: data => {
					this.productData = data
					this.productData.forEach(item => {
						if(item.product_count==0){
							item.product_count = "0";
						}
					});
				}
			});
		},
		//选择操作员
		selectQuality(){
			this.$refs.qualitySelector.open('','操作员',3);
		},
		//选择操作员回调
		selectQualityHandle(data){
			var obj = data[0];
			this.$refs.form.set({
				yg_id:obj.id,
				yg_name:obj.user_realname
			});
		},
		//选择仓库事件
		changeWarehouse(value){
			this.warehouse_type = value.type;
			this.productData = [];
		},
		//选择产品
		selectProduct(type){
			if(!this.$refs.form.get().warehouse_id){
				this.$message({
					message: '请先选择仓库'
				});
				return;
			}
			
			var product_ids = [];
			if (this.productData && this.productData.length > 0){
				this.productData.forEach(item => {
					console.log(item);
					product_ids.push(item.product_id);
				});
			}
			//产品弹框
			if(type == 1){
				this.$refs.productSelector.open(0,product_ids);
			//物料弹框
			}else if(type == 2){
				this.$refs.materialSelector.open(0,product_ids);
			}
		},
		//删除产品
		removeProduct(data){
			// 删除指定元素
			let index = this.productData.indexOf(data);
			if (index !== -1) {
				this.productData.splice(index, 1);
			}
		},
		//确认选择产品
		handleProductSelect(rows){
			if (rows){
				rows.forEach(item => {
					console.log(item);
					console.log('----------------1111-----------------');
					var product = {};
					product.product_id = item.id;
					product.product_code = item.product_code;
					product.product_name = item.product_name;
					product.unit = item.unit;
					product.product_spec = item.product_spec;
					product.product_type = item.type;
					console.log('----------------2222-----------------');
					product.product_count = 0;
					//查询库存
					this.request({
						url: '/inventory/product_list',
						data: {
							product_id: item.id,
							product_spec: item.product_spec,
							warehouse_id: this.$refs.form.get().warehouse_id
						},
						success: data => {
							console.log('----------------3333-----------------');
							console.log(data);
							product.db_stock_count = data[0].product_count;
							product.product_count = data[0].product_count+"";
							this.productData.push(product);
						}
					});
				});
			}
		},
		submit(state) {
			//校验数据
			this.$refs.form.submit({
				url: '/wms/inventory/editOrAdd',
				rule: {
					warehouse_id: '请选择仓库',
					reason_code: '请选择盘点原因',
					yg_id: '请选择操作员工',
					productData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.productData.length > 0){
								var flag = true;
								this.productData.forEach(item => {
									if(!item.product_count){
										flag = false;
									}
								});
								return flag;
							}else{
								return false;
							}
						},
						message: '请完善盘点商品明细'
					}]
				},
				dataFormatter: data => {
					data.id = this.id;
					data.state = state;
					data.product_list = this.productData;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.$bus.$emit('inventoryList.update',{});//刷新订单列表
					//打开任务列表
					this.redirectTo('/inventory_list');
				}
			});
		}
	}
}
</script>