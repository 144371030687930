<template>
	<edit></edit>
</template>

<script>
	import edit from './inventory_edit.vue'
	
	export default {
		name: 'm_inventory_add',
		
		components: {
			edit
		}
	}
</script>